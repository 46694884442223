import { GenericCampaignFormat } from '@ads/front-core/api'
import { lng } from '@ads/front-core/locales'

export const getCreationFormat = (format: GenericCampaignFormat) => {
  switch (format) {
    case 'COMMERCIAL_BREAK':
      return lng('campaign:marketplace.commercialBreak.formats.commercialBreak')
    case 'COMMERCIAL_BREAK_FULL_PAGE':
      return lng(
        'campaign:marketplace.commercialBreak.formats.commercialBreakFullPage',
      )
    case 'HALFPAGE':
      return lng('campaign:marketplace.broadFlex.formats.halfpage')
    case 'RECTANGLE':
      return lng('campaign:marketplace.broadFlex.formats.rectangle')
    case 'MIDTEXT':
      return lng('campaign:marketplace.broadFlex.formats.midtext')
    case 'ROTATING_CONTENT_BOX':
      return lng('campaign:marketplace.wpFlex.formats.rotatingContentBox')
    case 'ROTATING_CONTENT_BOX_XL':
      return lng('campaign:marketplace.wpFlex.formats.rotatingContentBoxXl')
    case 'ROTATING_HALFPAGE':
      return lng('campaign:marketplace.wpFlex.formats.rotatingHalfPage')
  }
}
