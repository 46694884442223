import styled, { css } from 'styled-components'
import {
  ITextButton as ITextButtonDS,
  TextButton as TextButtonDS,
  colors,
  fontWeights,
  fontSizes,
} from '@ads/front-ds'

export interface ITextButton extends ITextButtonDS {
  fontWeight?: keyof typeof fontWeights
  fontSize?: keyof typeof fontSizes
  color?: keyof typeof colors
}

export const TextButton = styled(TextButtonDS)<ITextButton>`
  ${p => css`
    font-weight: ${p.fontWeight
      ? p.theme.fontWeights[p.fontWeight]
      : p.theme.fontWeights.regular};
    ${p.fontWeight &&
    css`
      font-size: ${p.theme.fontSizes[p.fontSize]};
    `}
    ${p.color &&
    css`
      color: ${p.theme.colors[p.color]};
    `}
  `}
`
