import { GenericCampaignConfigurationType } from '@ads/front-core/api'
import { lng } from '@ads/front-core/locales'

export const getConfigurationType = (
  configurationType: GenericCampaignConfigurationType,
) => {
  switch (configurationType) {
    case 'THEME_PACKAGES':
      return lng('campaign:marketplace.partProducts.tabs.packages.title')
    case 'WEBSITE_PACKAGE':
      return lng('campaign:marketplace.partProducts.tabs.websitePackage.title')
    case 'SELECTED_WEBSITE':
      return lng('campaign:marketplace.partProducts.tabs.websites.title')
  }
}
