import { ProductType, Targeting } from '@ads/front-core/api'
import { POLAND_NAME } from '@ads/front-core/constants/countries'
import { TDictionary } from '@ads/front-core/interfaces'
import { isPublisherCampaign } from './campaign'

export const POLAND_OPTION_NAME = 'Poland'

export const hasTargetingElement = (elem): boolean =>
  elem?.included?.length > 0 || elem?.excluded?.length > 0 || !!elem?.attributes

const withIabCategories = ({
  iabCategories,
  formatForBackend,
  productTypeId,
}: {
  iabCategories: Targeting['iabCategories']
  formatForBackend: boolean
  productTypeId: ProductType
}): boolean => {
  if (isPublisherCampaign(productTypeId)) {
    return false
  }
  if (!formatForBackend || iabCategories?.attributes?.excludeSensitive) {
    return true
  }
  return (
    iabCategories?.included?.length > 0 || iabCategories?.excluded?.length > 0
  )
}

export const targetingIncludeOrExclude = <T>(
  elem: {
    included?: Array<T>
    excluded?: Array<T>
  },
  smartTargeting?: boolean,
) => {
  if (smartTargeting === true) {
    return {
      included: [],
    }
  }

  return {
    ...(elem?.included && elem?.excluded
      ? (elem.included || []).length > 0
        ? {
            included: elem.included,
          }
        : {
            excluded: elem.excluded || [],
          }
      : elem?.excluded
      ? {
          excluded: elem.excluded || [],
        }
      : {
          included: elem?.included || [],
        }),
  }
}

const included = <T extends keyof Targeting>(targeting: Targeting, key: T) =>
  ({
    included: (targeting || {})[key]?.included || [],
  } as Targeting[T])

export const formatTargeting = ({
  targeting,
  polandId,
  productTypeId,
  formatForBackend,
  smartTargeting,
}: {
  targeting: Targeting
  polandId?: number
  productTypeId?: ProductType
  formatForBackend?: boolean
  smartTargeting?: boolean
}): Targeting => {
  const withPoland =
    !polandId ||
    !targeting?.countries?.included?.length ||
    targeting?.countries?.included?.some(c => c === polandId)

  const withDemography = withDemographyTargeting({
    withPoland,
    productTypeId,
    smartTargeting,
  })

  return {
    gender: { included: [] },
    age: { included: [] },
    ...(withPoland
      ? {
          ...(withDemography || !formatForBackend
            ? {
                gender: included(targeting, 'gender'),
                age: included(targeting, 'age'),
              }
            : {}),
          regions: targetingIncludeOrExclude(targeting?.regions),
          ...(!smartTargeting && hasTargetingElement(targeting?.topic)
            ? {
                topic: {
                  included: targeting?.topic?.included || [],
                  excluded: targeting?.topic?.excluded || [],
                },
              }
            : {}),
        }
      : {
          regions: { included: [] },
        }),
    device: included(targeting, 'device'),
    cities: targetingIncludeOrExclude(targeting?.cities),
    countries: targetingIncludeOrExclude(targeting?.countries),
    ...(isPublisherCampaign(productTypeId)
      ? {
          rekids: included(targeting, 'rekids'),
        }
      : {
          domain: targetingIncludeOrExclude(targeting?.domain, smartTargeting),
        }),
    ...(withIabCategories({
      iabCategories: targeting?.iabCategories,
      formatForBackend,
      productTypeId,
    }) && hasTargetingElement(targeting?.iabCategories)
      ? {
          iabCategories: {
            included: !smartTargeting
              ? targeting?.iabCategories?.included || []
              : [],
            excluded: !smartTargeting
              ? targeting?.iabCategories?.excluded || []
              : [],
            attributes: {
              excludeSensitive:
                targeting?.iabCategories?.attributes?.excludeSensitive || false,
              includeDomainCategories:
                targeting?.iabCategories?.attributes?.includeDomainCategories ||
                false,
              includeArticleCategories:
                targeting?.iabCategories?.attributes
                  ?.includeArticleCategories || false,
            },
          },
        }
      : {}),
    ...(!smartTargeting && hasTargetingElement(targeting?.segments)
      ? {
          segments: {
            included: targeting?.segments?.included || [],
            excluded: targeting?.segments?.excluded || [],
          },
        }
      : {}),
    ...(!smartTargeting && hasTargetingElement(targeting?.retargetingSegments)
      ? {
          retargetingSegments: {
            included: targeting?.retargetingSegments?.included || [],
            excluded: targeting?.retargetingSegments?.excluded || [],
          },
        }
      : {}),
  }
}

export const withPolandTargeting = (
  targeting: Targeting,
  dictionary: TDictionary[],
): boolean => {
  const polandId = dictionary?.find(d => d.value === POLAND_NAME)?.id

  return (
    targeting?.countries?.included?.some(c => c === polandId) ||
    targeting?.countries?.excluded?.some(c => c === polandId)
  )
}

export const withSmartTargeting = (productType: ProductType) =>
  ['DISPLAY_CPC', 'DISPLAY_CPC_NATIVE'].includes(productType)

export const withDemographyTargeting = ({
  withPoland,
  productTypeId,
  smartTargeting,
}: {
  withPoland: boolean
  productTypeId: ProductType
  smartTargeting: boolean
}): boolean => {
  const withSmart = withSmartTargeting(productTypeId)
  return withSmart ? withPoland && !smartTargeting : withPoland
}
