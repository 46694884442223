/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreationsList } from '../models/CreationsList'
import type { RequestRejectCreation } from '../models/RequestRejectCreation'
import type { ResponseAdminCampaignCreations } from '../models/ResponseAdminCampaignCreations'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class AdminCreationService {
  /**
   * pobiera liste kreacji pogrupowaną po kampaniach
   * @returns ResponseAdminCampaignCreations Lista kampanii z kreacjami
   * @throws ApiError
   */
  public static adminCampaignCreations({
    page,
    limit,
    status,
    folder,
  }: {
    /**
     * numer strony do pobrania
     */
    page: number
    /**
     * liczba elementów do pobrania
     */
    limit: number
    /**
     * status kreacji
     */
    status: 'NEW' | 'ACCEPTED' | 'REJECTED'
    /**
     * folder, brak paramtru jeżli glowny
     */
    folder?: 'POSTPONED'
  }): CancelablePromise<ResponseAdminCampaignCreations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admin/campaign/creations',
      query: {
        page: page,
        limit: limit,
        status: status,
        folder: folder,
      },
    })
  }

  /**
   * Akceptuje kreacje
   * @returns void
   * @throws ApiError
   */
  public static creationAccept({
    requestBody,
  }: {
    requestBody?: CreationsList
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/campaign/creation/accept',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Blokuje kreacje
   * @returns void
   * @throws ApiError
   */
  public static creationsBlock({
    requestBody,
  }: {
    requestBody?: CreationsList
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/campaign/creation/block',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Odblokowuje kreacje
   * @returns void
   * @throws ApiError
   */
  public static creationsUnblock({
    requestBody,
  }: {
    requestBody?: CreationsList
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/campaign/creation/unblock',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Odrzuca kreację
   * @returns void
   * @throws ApiError
   */
  public static creationReject({
    creationId,
    requestBody,
  }: {
    /**
     * identyfikator kreacji reklamowej
     */
    creationId: number
    requestBody?: RequestRejectCreation
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/admin/campaign/creation/{creationId}/reject',
      path: {
        creationId: creationId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }
}
